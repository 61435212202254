import axios from 'axios';

export function APIFutura(token){
    const api = axios.create({
        baseURL:"https://api.medfutura.com.br/FuturaOLApp",
        headers: {
            'X-Custom-Token' : token, 
            'Content-Type':'application/json;charset=utf-8'
        }
    });
    
    return api
};