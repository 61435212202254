import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CardItem from "../components/CardItem/CardItem";
import TabelaSimples from "../components/Tabelas/TabelaSimples";
import BttnPadrao from "../components/Botoes/BttnPadrao";
// import itemList from "../assets/orderItens.json";
// import data from "../assets/orders.json";
import { saveAs } from 'file-saver';
import { APIFutura } from "../components/APIs/APIFutura";

function Pedido() {
	const [pedido, setPedido] = useState();
	const [pedidos, setPedidos] = useState();

	const numPed = window.location.search.slice(1);

	/////////// useEffect para fazer um request assim que a pagina é renderizada, o resultado dessa API é inserida em um useState
	useEffect(() => {
		APIFutura(JSON.parse(sessionStorage.getItem('medfuturaCrmTokenAtivo')))
			.get(`/api/OLAppPedido?id=${numPed}`)
			.then((result) => {
				setPedido({...result.data.data[0], dtEmissao:  new Date(parseInt(result.data.data[0].dtEmissao.replace('Date(', '').replace('/',''))), dtFatur: new Date(parseInt(result.data.data[0].dtFatur.replace('Date(', '').replace('/','')))})
			});

		APIFutura(JSON.parse(sessionStorage.getItem('medfuturaCrmTokenAtivo')))
			.get("/api/OLAppPedido")
			.then((result) => {
				setPedidos(result.data.data.map((info)=>{
					return{...info, dtEmissao:  new Date(parseInt(info.dtEmissao.replace('Date(', '').replace('/',''))), dtFatur: new Date(parseInt(info.dtFatur.replace('Date(', '').replace('/','')))}
				}));
			});
	}, [numPed]);
	/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

	const navigate = useNavigate();
	///////////
	const routePedido = (props) => {
		// so navigate não reseta o estado dos
		window.location.replace(
			window.location.origin + window.location.pathname + `?${props.id}`
		);
	};

	/////Função para saber se tem receita ou não o Pedido, no caso, se o tamanho do arquivo for == 0,
	function ReceitaMed(ped) {
		if (ped.receita.tamanho === 0) {
			return "NÃO";
		} else {
			return "SIM";
		}
	}

	//OBJ para colocar no componente tabela simples
	const head = {
		numPedido: "Pedido",
		status: "Status",
		cnpj_cliente: "CNPJ Cliente",
		vlTotal: "Vl. Total",
		dtFatur: "Faturamento",
	};

	//função que tem uma condicional, se exxistir mais de 1 pedido para o mesmo cliente ele retornará uma tabela com os outros pedidos
	//se não, retornara uma tabela vazia
	// function pedi(){
	// 	const pediEx = pedido && pedidos.filter((i) => i.cnpj_cliente === pedido.cnpj_cliente)
	// 	if(pediEx){
	// 		return (
	// 			<TabelaSimples
	// 				dados={pedidos.filter((i) => i.cnpj_cliente === pedido.cnpj_cliente)}
	// 				head={head}
	// 				onClick={routePedido}
	// 				altura="50vh"
	// 				/>
	// 		)
	// 	}else{
	// 		<TabelaSimples
	// 				head={head}
	// 				onClick={routePedido}
	// 				altura="50vh"
	// 				/>
	// 	}
	// };

	const buscaNF = () => {
		const fileContent = pedido?.arq_xml; 
		const blob = new Blob([fileContent], { type: 'text/plain;charset=utf-8' }); 
		// Use the saveAs function from the file-saver library to initiate the download 
		saveAs(blob, `NF${numPed}.xml`); 
	}

	return (
		<div className="m-5 grid md:grid-cols-12 grid-cols-4 gap-5 md:col-start-2 md:col-end-11 m-5">
			<div className="md:col-span-5 col-span-5 md:col-start-2 h-50 bg-component-whitesmoke p-5 rounded-xl shadow-md border border solid">
				<h3 className="text-center text-xl mb-2">
					Pedido Nº: {numPed}
					{pedido?.arq_xml.length > 1  &&
					<label	
							onClick={buscaNF}
							htmlFor="FATURADO"
							className="text-xs ml-5 p-2 bg-element-blue text-white rounded-xl cursor-pointer hover:bg-accent-orange"
						>
							Baixar NF
						</label>}
					{/* {pedido && pedido.status !== "RECEBIDO" && (
						<label
							htmlFor={"Receita"}
							className="text-xs ml-5 p-2 bg-element-blue text-white rounded-xl cursor-pointer hover:bg-accent-orange"
						>
							Adicionar Comprovante
						</label>
					)} */}
				</h3>
				<hr className="border-accent-orange" />
				<div className="mt-5 mb-5 md:grid md:grid-cols-2 md:gap-5">
					<div>
						<p className="mt-3">Cliente: {pedido && pedido.cnpj_cliente}</p>
						<p className="mt-3">Data: {pedido && pedido.dtFatur.toLocaleString()}</p>
						<p className="mt-3">Status: {pedido && pedido.status}</p>
					</div>
					<div>
						<p className="mt-3">Médico: {pedido && pedido.medico_nome}</p>
						<p className="mt-3">CRM: {pedido && pedido.medico_crm}</p>
						<p className="mt-3">Receita: {pedido && ReceitaMed(pedido)}</p>
					</div>
				</div>
				<hr className="border-accent-orange" />
				<h3 className="text-center text-xl mb-5 mt-5">Itens:</h3>
				<div className="md:m-3">
					{pedido &&
						pedido.itens.map((item, index) => (
							<CardItem data={item} key={index} />
						))}
				</div>
			</div>
			<div className="md:col-span-5 col-span-4 md:col-start-7 grid md:grid-cols-5 grid-cols-4 h-50 p-5">
				<div className="md:col-span-6 grid md:grid-cols-6 col-span-2 col-start-2 grid grid-cols-2 md:gap-5 mb-10 ">
					<BttnPadrao texto="Novo Pedido" onClick={() => navigate("/novo")} />
				</div>
				{/* { pedidos && (
					<>						
					<div className="bg-component-whitesmoke p-5 rounded-xl shadow-md border border solid lg:col-span-5 col-span-4 lg:mt-5  mt-5">
						<h5 className="text-center lg:text-lg mb-5">Outros pedidos desse cliente</h5>
							{pedidos && pedi()}
						</div>
					</>
				)} */}
				<div className="bg-component-whitesmoke p-2 rounded-xl shadow-md border border solid lg:col-span-5 col-span-4 lg:mt-5  mt-5">
					{pedidos && pedido && (
						<TabelaSimples
							dados={pedidos?.filter(
								(i) =>{
									return i.cnpj_cliente === pedido.cnpj_cliente}
							)}
							head={head}
							onClick={routePedido}
							dateTime={['dtEmissao','dtFatur']}
							money={['vlTotal']}
							altura="50vh"
						/>
					)}
				</div>
			</div>
		</div>
	);
}

export default Pedido;
