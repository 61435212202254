import React, { useEffect, useState } from "react";
import BttnPadrao from "../components/Botoes/BttnPadrao";
import FiltroSelect from "../components/Filtros/FIltroSelect";
import { useNavigate } from "react-router-dom";
import CardItem from "../components/CardItem/CardItem";
import toast from "react-hot-toast";
import axios from "axios";
import infoFrete from "./infoFrete.json";

// import clientes from "../assets/clientes.json";
// import listaItens from "../assets/itens.json";
import { APIFutura } from "../components/APIs/APIFutura";
import BttnInputFile from "../components/Botoes/BttnInputFile";

export default function NewOrder() {
	const [cliente, setCliente] = useState();
	const [nomeMedico, setNomeMedico] = useState();
	const [nCrm, setNCrm] = useState();
	const [item, setItem] = useState();
	const [itens, setItens] = useState([]);
	const [precos, setPrecos] = useState();
	const [produtos, setProdutos] = useState([]);
	const [estadoQtd, setestadoQtd] = useState(1);
	const [cep, setCep] = useState("");
	const [cidade, setCidade] = useState("");
	const [estado, setEstado] = useState("");
	const [nomeCliente, setNomeCliente] = useState("");
	const [validado, setValidado] = useState(false);
	const [telefone, setTelefone] = useState("");
	const [obs, setObs] = useState("");
	const [endereco, setEndereco] = useState("");
	const [email, setEmail] = useState("");
	const [freteSelect, setFreteSelect] = useState();
	const [optPay, setOptPay] = useState();
	const [receita, setReceita] = useState();
	const [comprovante, setComprovante] = useState();

	/////////////////////////// aqui faz a requisão para a API para pegar os itens que tem no estoque ///////////////////////
	useEffect(() => {
		APIFutura(JSON.parse(sessionStorage.getItem("medfuturaCrmTokenAtivo")))
			.get("/api/OLAppEstoqueItem")
			.then((result) => {
				console.log(result.data.data);
				setProdutos(result.data.data);
			});
		APIFutura(JSON.parse(sessionStorage.getItem("medfuturaCrmTokenAtivo")))
			.get("/api/OLAppConfig/?cod_modulo=95")
			.then((result) => {
				console.log(result.data.data);
				setPrecos(
					result.data.data.map((info) => {
						return {
							...info,
							valor: info.valor.split("|").map((preco) => preco.split(";")),
						};
					})
				);
				// setPrecos(result.data.data);
			});
	}, []);

	//Aqui pega o Nome do Usuario da sessão ////////
	const user = JSON.parse(sessionStorage.getItem("medfuturaCrmAtivo")).userName;

	const infoItem =
		item && produtos.filter((i) => i.descricao === item.value)[0];

	////////////Aqui temos 2 funções uma que pega a quantidade do item e faz uma condicional, que se a qtd for igual a 1 sera retornado um valo
	///////////se a qtd for 2 sera retornado o vl 2, por causa dos descontos, se for uma certa quantidade tera um descondo no proço

	function handleVl(qauntidade) {
		if (infoItem) {
			if (
				[
					"sac@greencarestore.com",
					"jeniffer.augusto@greencarepharma.com.br",
					"luci.sampaio@greencarepharma.com.br",
				].includes(
					JSON.parse(sessionStorage.getItem("medfuturaCrmAtivo")).userEmail
				)
			) {
				const precoBase = Number(
					precos?.filter((data) => {
						return (
							data.chave.includes(infoItem.codIteManual) &&
							data.chave.includes("base")
						);
					})[0].valor[0][0]
				);
				let desconto = 0;
				if (qauntidade > 1) {
					const descs = precos?.filter((data) => {
						return (
							data.chave.includes(infoItem.codIteManual) &&
							data.chave.includes("descontos")
						);
					})[0].valor;

					for (let qtd of descs) {
						if (
							Number(qtd[0]) === qauntidade ||
							descs.indexOf(qtd) === descs.length - 1
						) {
							desconto = (precoBase * Number(qtd[1])) / 100;
							break;
						}
					}
				}
				return precoBase - desconto;
			} else {
				if (item && qauntidade > 1) {
					return Number(infoItem.vl2);
				} else if (item && qauntidade === 1) {
					return Number(infoItem.vl1);
				}
			}
		} else {
			return;
		}
	}

	function HndleSetQTD(soma) {
		if (estadoQtd + soma > 0) {
			setestadoQtd((setestadoQtd) => (setestadoQtd += soma));
		}
	}

	function buscaCep() {
		axios
			.get(`https://brasilapi.com.br/api/cep/v1/${cep}`)
			.then((resultado) => {
				setCidade(resultado.data.city);
				setEstado(resultado.data.state);
				setFreteSelect(
					infoFrete.filter(
						(data) =>
							Number(cep) >= data.cepInicial && Number(cep) <= data.cepFinal
					)[0]
				);
				console.log(resultado);
			})
			.catch((err) => toast.error("CEP inválido."));
	}

	function buscaCliente() {
		const carregando = toast.loading("Buscando cliente...");
		APIFutura(JSON.parse(sessionStorage.getItem("medfuturaCrmTokenAtivo")))
			.get(`/api/OLAppCliente/?cpf=${cliente}`)
			.then((resultado) => {
				if (resultado.data.status) {
					setValidado(true);
					setCliente(resultado.data.data.cnpj_cpf);
					setEmail(resultado.data.data.email);
					setNomeCliente(resultado.data.data.nomeRazao);
					setCep(resultado.data.data.cep);
					toast.dismiss(carregando);
					toast.success("Cliente encontrado.");
				} else {
					toast.dismiss(carregando);
					toast.error("Cliente não cadastrado.");
				}
			})
			.catch((err) => toast.error("Erro na comunicação com o servidor."));
	}

	//////////// Aqui temos um handlesumit para o item, que pega os itens que foram adicionados e coloca essa informação em um useState

	const addItem = (e) => {
		e.preventDefault();
		const obj = {
			descricao: infoItem.descricao,
			cod_prod_vendedor: infoItem.codIteManual,
			quant: estadoQtd,
			vr_unitario: handleVl(estadoQtd),
			unidade: infoItem.uniCompra,
		};
		console.log(obj);
		setItens([...itens, obj]);
		setestadoQtd(1);
	};

	///////Aqui temos um handle que cria um objeto para enviar para um post na API de criar novo pedido
	const navigate = useNavigate();
	const handleBttn = (flag) => {
		let pay;

		if (flag === 1) {
			if (
				itens.length > 0 &&
				(receita || obs) &&
				nomeCliente &&
				cliente &&
				((email && telefone && endereco) || validado) &&
				cep
			) {
				switch (optPay.value) {
					case "Cartão":
						pay = 8;
						break;
					case "Tranferência":
						pay = 4;
						break;
					default:
						pay = null;
						break;
				}

				const newOrder = {
					cnpj_cliente: cliente,
					id_forma_pagamento: 1,
					cod_formPg: pay,
					prazo_entrega: 1,
					codEmpresa: 1,
					obs: obs,
					ordem_de_compra: "-",
					medico_nome: nomeMedico,
					medico_crm: nCrm,
					nome: nomeCliente,
					email: email,
					telefone: telefone,
					cep: cep,
					endereco: cidade + ", " + estado + ", " + endereco,
					itens: itens,
					receita: receita,
					comprovante: comprovante,
				};
				// console.log(newOrder)

				const carregando = toast.loading("Processando venda...");
				APIFutura(JSON.parse(sessionStorage.getItem("medfuturaCrmTokenAtivo")))
					.post("/api/OLAppPedido", newOrder)
					.then((result) => {
						console.log(result);
						if (result.data.status) {
							toast.success("Pedido cadastrado com sucesso!");
							toast.dismiss(carregando);
						} else {
							toast.error(
								"Erro no processamento. Entrar em contato com suporte."
							);
							toast.dismiss(carregando);
						}
					})
					.catch(() => {
						toast.error(
							"Falha no registro do pedido. Favor entrar em contato com o administrador."
						);
						toast.dismiss(carregando);
					});

				navigate("/home");
			} else {
				toast.error("Dados necessários não foram preenchidos.");
			}
		} else if (flag === 0) {
			console.log("Cancelado");
			navigate("/home");
		}
	};

	const removeItem = (index) => {
		setItens([...itens.slice(0, index), ...itens.slice(index + 1)]);
	};
	freteSelect && console.log(freteSelect);
	return (
		<div className="m-5 grid md:grid-cols-12 grid-cols-4 gap-y-5">
			<div className="md:col-span-10 col-span-4 grid md:grid-cols-10 grid-cols-4 md:col-start-2 bg-component-whitesmoke rounded-xl mb-15">
				<div className="mt-5 ml-5 mr-5 md:col-span-10 col-span-4 text-center text-xl">
					<h2 className="mb-3">Registro de novo pedido</h2>
					<hr className="border-accent-orange" />
				</div>
				<div className="mt-5 ml-5 mr-5 md:col-span-10 grid md:grid-cols-12 col-span-4 grid-cols-1 gap-5">
					<div name="div" className="col-span-4 md:col-span-2 col-span-1">
						<p htmlFor="Vendedor">Vendedor:</p>
						<p name="Vendedor">{user}</p>
					</div>
					<div name="div" className="col-span-4 md:col-span-2 grid">
						<label htmlFor="Medico">Médico:</label>
						<input
							className="p-2 rounded-lg border border-solid w-4/5"
							name="Medico"
							type="text"
							value={nomeMedico}
							onChange={(e) => setNomeMedico(e.target.value)}
							placeholder="Nome do médico"
							required
						/>
					</div>
					<div name="div" className="col-span-4 md:col-span-2 grid">
						<label htmlFor="CRM">CRM:</label>
						<input
							className="p-2 rounded-lg border border-solid w-4/5"
							name="CRM"
							type="text"
							value={nCrm}
							maxLength="12"
							onChange={(e) => setNCrm(e.target.value)}
							required
						/>
					</div>
					<div name="div" className="col-span-4 md:col-span-2 grid">
						<label>Forma de pagamento:</label>
						<FiltroSelect
							placeholder="Forma de pagamento"
							id="Produto"
							multi={false}
							options={[
								{ id: 4, descricao: "Tranferência" },
								{ id: 8, descricao: "Cartão" },
							]}
							chave={"descricao"}
							onChange={setOptPay}
						/>
					</div>
					<div name="div" className="col-span-4 md:col-span-2 grid">
						<label htmlFor="nomeCliente">Cliente:</label>
						<input
							className="p-2 rounded-lg border border-solid w-4/5"
							name="nomeCliente"
							type="text"
							value={nomeCliente}
							onChange={(e) => setNomeCliente(e.target.value)}
							placeholder="Nome do cliente"
							required
						/>
					</div>
					<div name="div" className="col-span-4 md:col-span-2 grid">
						<div>
							<label htmlFor="Cliente"> Cliente (CPF): </label>
							<span
								className="ml-2 text-element-blue cursor-pointer"
								onClick={buscaCliente}
							>
								Buscar
							</span>
						</div>
						<input
							className="p-2 rounded-lg border border-solid w-4/5"
							name="Cliente"
							placeholder="Apenas números"
							type="text"
							value={cliente}
							onChange={(e) => {
								setCliente(e.target.value);
								setValidado(false);
							}}
							maxLength="11"
							required
						/>
					</div>
					<div name="div" className="col-span-4 md:col-span-2 grid">
						<label htmlFor="email"> Email: </label>
						<input
							className="p-2 rounded-lg border border-solid w-4/5"
							name="email"
							placeholder="Email"
							type="email"
							value={email}
							onChange={(e) => setEmail(e.target.value)}
							required
						/>
					</div>
					<div name="div" className="col-span-4 md:col-span-2 grid">
						<label htmlFor="telefone"> Telefone: </label>
						<input
							className="p-2 rounded-lg border border-solid w-4/5"
							name="telefone"
							placeholder="Apenas números"
							type="tel"
							value={telefone}
							onChange={(e) => setTelefone(e.target.value)}
							maxLength="13"
							required
						/>
					</div>
					<div name="div" className="col-span-4 md:col-span-2 grid">
						<div>
							<label htmlFor="cep">CEP:</label>
							<span
								className="ml-2 text-element-blue cursor-pointer"
								onClick={buscaCep}
							>
								Buscar
							</span>
						</div>
						<input
							className="p-2 rounded-lg border border-solid w-4/5"
							name="cep"
							type="text"
							value={cep}
							onChange={(e) => setCep(e.target.value)}
							placeholder="Apenas números"
							maxLength="8"
							required
						/>
					</div>
					<div className="col-span-4 md:col-span-2 col-span-2">
						<label htmlFor="Estado">
							Estado:
							<p className="pt-2" mame="EAN">
								{estado && estado}
							</p>
						</label>
					</div>
					<div className="col-span-4 md:col-span-2 col-span-2">
						<label htmlFor="Cidade">
							Cidade:
							<p className="pt-2" mame="EAN">
								{cidade && cidade}
							</p>
						</label>
					</div>
					<div name="div" className="col-span-4 md:col-span-2 grid">
						<label htmlFor="endereco">Endereço:</label>
						<input
							className="p-2 rounded-lg border border-solid w-4/5"
							name="endereco"
							type="text"
							value={endereco}
							onChange={(e) => setEndereco(e.target.value)}
							placeholder="Endereço"
							required
						/>
					</div>
					<div name="div" className="col-span-4 md:col-span-4 grid">
						<label htmlFor="obs">Obs:</label>
						<textarea
							className="p-2 rounded-lg border border-solid w-5/5"
							name="obs"
							value={obs}
							onChange={(e) => setObs(e.target.value)}
							required
						/>
					</div>
					<div className="col-span-2 md:col-span-4 md:mt-5">
						<BttnInputFile texto="Adicionar Receita" arquiva={setReceita} />
					</div>
					<div className="col-span-2 md:col-span-4 md:mt-5">
						<BttnInputFile
							texto="Adicionar Comprovante"
							arquiva={setComprovante}
						/>
					</div>
					<hr className="border-accent-orange col-span-4 md:col-span-12" />
				</div>
				<div className="mt-5 ml-5 mr-5 md:col-span-10 col-span-4 grid  md:grid-cols-4 gap-5">
					<div className="grid col-span-1 gap-5">
						<p>Valor Frete:</p>
						<p>
							{freteSelect &&
								freteSelect.frete?.toLocaleString("pt-BR", {
									style: "currency",
									currency: "BRL",
								})}
						</p>
					</div>
					<div className="grid col-span-1 gap-5">
						<p>Prazo Mínimo:</p>
						<p>{freteSelect && freteSelect.prazoMinimo}</p>
					</div>
					<div className="grid col-span-1 gap-5">
						<p>Prazo Máximo:</p>
						<p>{freteSelect && freteSelect.prazoMaximo}</p>
					</div>
					<div className="grid col-span-1 gap-5">
						<p>Frete Grátis a partir de:</p>
						<p>{freteSelect && freteSelect.gratis?.toLocaleString("pt-BR", {
									style: "currency",
									currency: "BRL",
								})}</p>
					</div>
					<hr className="border-accent-orange col-span-4" />
				</div>
				<form
					className="mt-5 ml-5 mr-5 md:col-span-10 col-span-4 grid md:grid-cols-6 grid-cols-4 gap-5"
					onSubmit={(e) => addItem(e)}
				>
					<div className="pt-2 md:col-span-1 col-span-4">
						<label htmlFor="Produto">Produto: </label>
						{produtos.length > 0 && (
							<FiltroSelect
								placeholder="Produto"
								id="Produto"
								multi={false}
								options={produtos}
								chave={"descricao"}
								onChange={setItem}
							/>
						)}
					</div>
					<div className="md:col-span-1 col-span-2">
						<label htmlFor="EAN">
							EAN:
							<p className="pt-2" mame="EAN">
								{infoItem && infoItem.codBarraEan13}
							</p>
						</label>
					</div>
					<div className="md:col-span-1 col-span-2">
						<label htmlFor="Unidade">
							Unidade:
							<p className="pt-2" mame="EAN">
								{infoItem && infoItem.uniCompra}
							</p>
						</label>
					</div>
					<div className="md:col-span-1 col-span-2">
						<label htmlFor="Quantidade">
							Quantidade:
							<div className="pt-1 grid grid-cols-[5%_30%_5%] gap-1">
								<span
									className="text-center text-xl hover:cursor-pointer"
									onClick={() => HndleSetQTD(-1)}
								>
									&#45;
								</span>
								<input
									className="p-2 text-center rounded-lg border border-solid appearance-none"
									mame="Quantidade"
									type="text"
									value={estadoQtd}
									readOnly
								/>
								<span
									className="text-center text-xl hover:cursor-pointer"
									onClick={() => HndleSetQTD(1)}
								>
									&#43;
								</span>
							</div>
						</label>
					</div>
					<div className="md:col-span-1 col-span-2">
						<label htmlFor="Valor">
							Valor Unitário:
							<p className="pt-2">
								{estadoQtd &&
									handleVl(estadoQtd)?.toLocaleString("pt-BR", {
										style: "currency",
										currency: "BRL",
									})}
							</p>
						</label>
					</div>
					<div className="md:col-span-1 col-span-2 grid m-3">
						<BttnPadrao texto="Adicionar" />
					</div>
					<hr className="border-accent-orange md:col-span-6 col-span-4" />
				</form>
				<div className="mt-5 ml-5 mr-5 md:col-span-10 col-span-4">
					<h4 className="text-center md:text-lg">Itens Inseridos</h4>
					{itens &&
						itens.map((item, index) => {
							return (
								<div
									key={index}
									className="md:col-span-10 col-span-4 grid md:grid-cols-10 md:gap-5"
								>
									<div className="md:col-span-9 col-span-4">
										<CardItem data={item} />
									</div>
									<div className="md:col-span-1 grid md:mt-5 col-span-2">
										<BttnPadrao
											texto="&#10006;"
											onClick={() => removeItem(index)}
										/>
									</div>
								</div>
							);
						})}
				</div>
			</div>
			<div className="col-span-4 grid grid-cols-2 md:col-start-5 gap-5">
				<div className="col-span-1 grid">
					<BttnPadrao texto="Cancelar" onClick={() => handleBttn(0)} />
				</div>
				<div className="col-span-1 grid">
					<BttnPadrao texto="Confirmar" onClick={() => handleBttn(1)} />
				</div>
			</div>
		</div>
	);
}
