var UserProfile = (() => {
    var nome    = "";
    var cod     = 0
    var token   = ""
    var email   = ""
    
    var setToken = (tokenAtual) => {
        token = tokenAtual
    }
    
    var getToken = () => {
        return token
    }

    var getNome = function() {
        return nome;    
        };
    
    var setNome = function(nomeAtual) {
        nome = nomeAtual;     
        };
    
    var getCod = function() {
        return cod;    
    };

    var setCod = function(codAtual) {
        cod = codAtual;     
    };

    var setEmail = (emailAtual) => {
        email = emailAtual
    }
    
    var getEmail = () => {
        return email
    }

    return {
        getNome:  getNome,
        setNome:  setNome,
        getCod:   getCod,
        setCod:   setCod,
        getToken: getToken,
        setToken: setToken,
        getEmail: getEmail,
        setEmail: setEmail,
    }

})();

export default UserProfile;