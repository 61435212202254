import axios from 'axios';
import UserProfile from './UserProfile';



function AutenticadorLogin({email, password}){

    const body = {
        email:email,
        password:password
    };

    const url = 'https://api.medfutura.com.br/FuturaOLApp/api/Usuario'

    const redirecionar = axios.post(url, body)
    .then((response) =>{
        if(response.data ==='Usuário não encontrado. Tente novamente.'){
            return 'fail'
        }
        else{
            UserProfile.setCod(response.data.codUsuario)
            UserProfile.setNome(response.data.usuario)
            UserProfile.setEmail(response.data.email)
            UserProfile.setToken(response.data.token)
            let activeUser = {
                userAuth:  response.data.data.token,
                userName:  response.data.data.usuario,
                userCod:   response.data.data.codUsuario,
                userEmail: response.data.data.Funcionario.email
            }
            sessionStorage.setItem('medfuturaCrmAtivo',JSON.stringify(activeUser))
            sessionStorage.setItem('medfuturaCrmTokenAtivo', JSON.stringify(activeUser.userAuth))

            const teste = {
                home:'/home',
                teste:activeUser
            }
            return teste
        }
    })
    .catch(function(error){
        console.log(error);
    })
    return redirecionar

}
export default AutenticadorLogin;