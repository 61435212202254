import React, {useState, useCallback} from 'react';

import { useDropzone } from "react-dropzone";
import { MdDeleteForever } from "react-icons/md";

export default function BttnInputFile(props) {
    const [file, setFile] = useState();
	const [bit, setBit] = useState();
	const [extension, setExtension] = useState();

    /// Dropzone foi criado aqui, essa ferramenta faz um "uploade" do arquivo e pega os dados e armazena no useState para depois enviar os bits junto com as informações para o banco /////////////////////////////////////////////////////////////////////////////////////
	const onDrop = useCallback((files) => {
        var data = {
            nome: files[0].name,
            extensao: "",
            arquivo: [],
            tamanho: files[0].size,

        }
		setFile(files);
		files.forEach((file) => {
			const reader = new FileReader();

			reader.onabort = () => console.log("file reading was aborted");
			reader.onerror = () => console.log("file reading has failed");
			reader.onload = () => {
				// Do whatever you want with the file contents
				const binaryStr = reader.result;
				console.log(binaryStr);
				console.log(Object.keys(binaryStr));
				const teste = new Int8Array(binaryStr);
				const teste234 = new Uint8Array(binaryStr);
				const decoder = new TextDecoder("utf-8");
				const string2 = decoder.decode(teste);
				console.log(reader);
				console.log(typeof string2);
				console.log(teste234);
				const bytesArray = Array.from(teste234);
				console.log(bytesArray);
				setBit(bytesArray);
                data.arquivo = bytesArray
				const string = file.name;
				const ultimapalavra = file.name.lastIndexOf(".");
				if (ultimapalavra !== -1) {
					const substringAposUltimoPonto = string
						.substring(ultimapalavra + 1)
						.trim();
					const palavras = substringAposUltimoPonto.split(" ");
					const primeiraPalavraDepoisPonto = palavras[0];
					setExtension(primeiraPalavraDepoisPonto);
                    data.extensao = primeiraPalavraDepoisPonto
				}
			};
			reader.readAsArrayBuffer(file);
		});
        props.arquiva(data)
	}, []);

	const offDrop = useCallback(() => {
		setFile(null);
		props.arquiva(null);
	}, []);

	const dropzone = useDropzone({
		onDrop,
		accept: {
			"application/pdf": [".pdf"],
			"image/*": [".png", ".gif", ".jpeg", ".jpg"],
		},
	});

	///////////Função que tem uma condicional, que se não existir um arquivo ele retorna um span para ADICIONAR UMA RECEITA
	///////////E se existir um arquivo ele coloca o nome do arquivo
	function verifyFile(files) {
		if (!files) {
			return (
				<>
					<span>{props.texto}</span>
				</>
			);
		} else if (files) {
			return (
				<>
					<span className="text-xs truncate">{files[0].name}</span>
				</>
			);
		}
	}
  return (
    <div
        name="div"
        className="col-span-4 md:col-span-4 md:mt-5"
        {...dropzone.getRootProps()}
    >
        <label
            htmlFor={"Receita"}
            className="text-sm p-3 bg-element-blue text-white rounded-xl cursor-pointer hover:bg-accent-orange"
        >
            <input
                className="w-4/5"
                {...dropzone.getInputProps({ className: "w-4/5" })}
            />
            {verifyFile(file)}
        </label>
        {file && (
            <label
                htmlFor={"Receita"}
                className="bg-element-blue text-white rounded-xl cursor-pointer hover:bg-accent-orange"
            >
                <button type="button" onClick={offDrop}>
                    <MdDeleteForever />
                </button>
            </label>
        )}
    </div>
  )
}
