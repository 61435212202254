import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import FiltroSelect from "../components/Filtros/FIltroSelect";
import BttnPadrao from "../components/Botoes/BttnPadrao";
// import Pedidos from "../components/Pedidos/Pedidos";
import TabelaSimples from "../components/Tabelas/TabelaSimples"
import { APIFutura } from "../components/APIs/APIFutura";
import toast from "react-hot-toast";

export default function Home() {
	const [compras, setCompras] = useState([]);
	const [filtroCliente, setFiltroCliente] = useState([]);
	const [filtroClienteNome, setFiltroClienteNome] = useState([]);
	const [filtroCrm, setFiltroCrm] = useState([]);
	const [filtroMedico, setFiltroMedico]= useState([]);

	useEffect(() => {
		APIFutura(JSON.parse(sessionStorage.getItem('medfuturaCrmTokenAtivo'))).get('/api/OLAppPedido')
		.then((result) =>{
			try{
				setCompras(result.data.data.map((info)=>{
					return{...info, dtEmissao:  new Date(parseInt(info.dtEmissao.replace('Date(', '').replace('/',''))), dtFatur: new Date(parseInt(info.dtFatur.replace('Date(', '').replace('/','')))}
				}));
			}
			catch(err){
				console.log(err)
				toast.error(result.data.message)
			}
			
		})
	}, []);


	let navigate = useNavigate();
	const routePedido = obj => {
		const path = `/detalhe?${obj.id}`;
		navigate(path);
	};

	
	const head = {
		numPedido: 'Pedido',
		cnpj_cliente: 'CPF Cliente',
		nomeRazao: 'Nome Cliente',
		medico_crm:'CRM', 
		medico_nome:'Médico',
		status:'Status',
		dtEmissao: 'Emissão',
		dtFatur: 'Faturamento',
		vlTotal:'Vl. Total'
	}

//função que retorna um filtro, que quando selecionar um cliente, filtrará só os pedidos desse cliente
	function camadaFiltro() {
		let array = compras;
		if (filtroCliente.length > 0) {
			array = array?.filter((item) => {
				return filtroCliente.find((filtro) => {
					return item.cnpj_cliente === filtro.value;
				});
			});
		};
		if (filtroClienteNome.length > 0) {
			array = array?.filter((item) => {
				return filtroClienteNome.find((filtro) => {
					return item.nomeRazao === filtro.value;
				});
			});
		};
		if (filtroCrm.length > 0) {
			array = array?.filter((item) => {
				return filtroCrm.find((filtro) => {
					return item.medico_crm === filtro.value;
				});
			});
		};
		if (filtroMedico.length > 0) {
			array = array?.filter((item) => {
				return filtroMedico.find((filtro) => {
					return item.medico_nome === filtro.value;
				});
			});
		};
		
		return array;
	}

	const obj = camadaFiltro();
	return (
		<>
			<div className="m-5  md:col-start-2 md:col-end-11 ">
				<div className="md:col-span-6 col-span-4 grid md:grid-cols-7 grid-cols-4 gap-5 md:col-start-2 m-5">
					<div className="md:col-span-1 md:col-start-2 col-span-2">
						<FiltroSelect
							placeholder="CPF Cliente"
							multi={true}
							options={compras}
							chave={"cnpj_cliente"}
							onChange={setFiltroCliente}
						/>
					</div>
					<div className="md:col-span-1 col-span-2">
						<FiltroSelect
							placeholder="Cliente"
							multi={true}
							options={compras}
							chave={"nomeRazao"}
							onChange={setFiltroClienteNome}
						/>
					</div>
					<div className="md:col-span-1 col-span-2">
						<FiltroSelect
							placeholder="CRM"
							multi={true}
							options={compras}
							chave={"medico_crm"}
							onChange={setFiltroCrm}
						/>
					</div>
					<div className="md:col-span-1 col-span-2">
						<FiltroSelect
							placeholder="Médico"
							multi={true}
							options={compras}
							chave={"medico_crm"}
							onChange={setFiltroMedico}
						/>
					</div>
					<div className="col-span-2">
							<BttnPadrao texto="Novo Pedido" onClick={() => navigate("/novo")} />
					</div>
				</div>
				<div className="md:col-span-5 col-span-4 m-5">
							<h5 className="text-center md:text-lg md:mb-2">Pedidos</h5>
					{compras.length > 0 && (
						<TabelaSimples dados={obj} head={head} altura="65vh" onClick={routePedido} money={["vlTotal"]} dateTime={['dtEmissao','dtFatur']} />
					)}
						{/* <div className="bg-component-whitesmoke p-5 rounded-xl shadow-md border border solid">
							<Pedidos pedidos={obj} />
						</div> */}					
				</div>
				
			</div>
		</>
	);
}
